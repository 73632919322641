.book-table-content-area {
    background-color: #21040c;
    background-image: url(../assets/book-table/glass-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    overflow: hidden;
    .title {
        font-family: "Yeseva One", serif;
        font-size: 64px;
        color: #087b6f;
        line-height: 60px;
    }
    .title2 {
        font-family: "Yeseva One", serif;
        font-size: 36px;
        color: #fff;
        line-height: 36px;
    }
    .title3 {
        font-family: "Yeseva One", serif;
        font-size: 32px;
        color: #d1b982;
    }
    .sub-text1 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 24px;
        color: #d1b982;
    }
    .sub-text2 {
        font-family: "Sacramento", cursive;
        font-size: 48px;
        color: #fff;
    }
    p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
    }
    .form-control,
    .form-select {
        background-color: #000000;
        border-color: #505050;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        height: 60px;
    }
    .form-control::placeholder,
    .form-select::placeholder {
        color: #666666;
    }
    .book-btn {
        background-color: #7c9aa0;
        border-color: #7c9aa0;
        font-size: 20px;
        color: #000000;
        font-weight: 500;
        min-width: 300px;
        height: 60px;
    }
    .right-line {
        background-image: url(../assets/book-table/line.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
    }

}

@media (max-width: 768px) {
    .book-table-content-area .title {
        font-size: 40px;
        line-height: 40px;
    }
    .book-table-content-area .title2 {
        font-size: 34px;
        line-height: 34px;
    }
    .book-table-content-area p {
        font-size: 12px;
    }
    .book-table-content-area .right-line {
        background: none;
    }
}
