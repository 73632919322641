@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rozha+One&family=Sacramento&family=Sawarabi+Mincho&family=Yeseva+One&display=swap");


body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }


.navbar {
  background-color: #000000 !important;
  padding-top: 30px;
  padding-bottom: 30px;
  .form-select {
    width: 125px;
  }
  .nav-link {
    color: #087B6F;
    font-family: "Bungee", sans-serif;
    font-size: 16px;
  }
  .nav-link:hover {
    color: #fff;
  }
}

.home-contact-sec {
  background-color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  h2 {
    font-size: 64px;
    color: #d1b982;
    font-family: "Yeseva One", serif;
  }
  .map {
    background-color: #ddd;
    height: 322px;
  }
  .lable {
    color: #fff;
  }
  .value {
    color: #087b6f;
    text-decoration: none;
  }
}

footer {
  background-color: #000;
  color: #4b4b4b;
  .footer-menu {
    padding-bottom: 30px;
    a {
      font-size: 16px;
      color: #4b4b4b;
      font-family: "Bungee", sans-serif;
      text-decoration: none;
      margin: 0 10px;
    }
  }
}

.inner-banner {
  background-size: cover;
  background-position: center;
  min-height: 368px;
  color: #fff;
  margin-top: 138px;
  h1 {
    font-family: "Yeseva One", serif;
    font-size: 48px;
  }
  .sub-text {
    font-family: "Yeseva One", serif;
    font-size: 20px;
  }
}

.followus-box {
  border-radius: 10px;
  background-color: #1e020a;
  border: 3px solid #C54A4A;
  padding: 5px;
  font-size: 24px;
  color: #fff;
  display: flex;
  .border-right {
    border-right: 1px solid #C54A4A;
  }
  .followus-btn {
    background-color: #C54A4A;
    color: #fff;
    border: 2px solid #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    height: 60px;
    padding: 10px 60px;
    border-radius: 6px;
  }
  .tag-text {
    color: #D1B982;
  }
}

@media (max-width: 768px) {
  .inner-banner {
    min-height: 185px;
    margin-top: 80px;
    h1 {
      font-size: 36px;
    }
    .sub-text {
      font-size: 16px;
    }
  }
  .home-contact-sec h2 {
    font-size: 40px;
  }

  .navbar  {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-nav {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    flex-direction: row;
  }
  .navbar .nav-link {
    min-width: 33.33%;
  }
  .followus-box {
    font-size: 16px;
    display: block;
    text-align: center;
    padding: 5px 0 0 0;
  }
  .followus-box .followus-btn {
    padding: 8px 40px;
    font-size: 20px;
    height: auto;
    margin-top: 10px;
    width: 100%;
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

input {
  color-scheme: dark;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(.7) !important;
  background-size: 30px;
    width: 30px;
    height: 30px;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.custom-accordion {
  .accordion-item {
    background-color: transparent;
    border: 0;
  }
  .accordion-button {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid #fff;
    border-radius: 0 !important;
    padding: 1rem 0;
    color: #D1B982;
    font-size: 32px;
    font-family: "Yeseva One", serif;
  }
  .accordion-body {
    padding: 1rem 0;
  }
}