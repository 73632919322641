.img-size {
    height: auto;
    width: 100%;    
}

.carousel-sec1 {
    overflow: hidden;
    padding: 0%;
    margin-top: 138px;
    background-color: #21040c;
    .banner {
      min-height: 816px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .banner1 {
      background-image: url(../assets/slider-img01.jpg);
      background-position: right;
    }
    .banner2 {
      background-image: url(../assets/slider-img02.jpg);
    }
    .banner3 {
      background-image: url(../assets/slider-img03.jpg);
    }
    .banner4 {
      background-image: url(../assets/slider-img04.jpg);
    }
    .banner5 {
      background-image: url(../assets/slider-img05.jpg);
    }
    
    .carousel-caption {
      bottom: 40% !important;
    }
    .titles {
      font-family: "Rozha One", serif;
      color: #d1b982;
      line-height: 85px;
      text-align: left;
    }
    .font1 {
      font-size: 64px;
    }
    .font2 {
      font-size: 96px;
      span {
        font-family: "Sawarabi Mincho", serif;
        color: #fff;
      }
    }
    .font3 {
      color: #fff;
      font-size: 72px;
    }
    .hookah1 {
      position: absolute;
      bottom: 40px;
    }
    .book-btn {
      background-color: #000000;
      border: 2px solid #087B6F;
      font-size: 20px;
      color: #fff;
      min-width: 250px;
      padding: 10px 0;
      font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-top: 10px;
    }
    .book-btn:hover {
      background-color: #087B6F;
    }
  }

  @media (max-width: 768px) {
    .carousel-sec1 {
      margin-top: 80px;
    }
    .carousel-sec1 .banner {
      min-height: 70vh;
    }
    .carousel-sec1 .titles {
      line-height: 48px;
    }
    .carousel-sec1 .font1 {
      font-size: 26px;
    }
    .carousel-sec1 .font2 {
      font-size: 48px;
    }
    .carousel-sec1 .font3 {
      font-size: 48px;
    }
    .carousel-caption {
      left: 5%;
      right: 5%;
    }
    .carousel-sec1 .carousel-caption {
      bottom: 30% !important;
    }
   }