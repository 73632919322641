.carousel-sec {
  background-image: url(../assets/banner-img.png);
  min-height: 816px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .titles {
    font-family: "Rozha One", serif;
    color: #d1b982;
    line-height: 85px;
  }
  .font1 {
    font-size: 64px;
  }
  .font2 {
    font-size: 96px;
    span {
      font-family: "Sawarabi Mincho", serif;
      color: #fff;
    }
  }
  .font3 {
    color: #fff;
    font-size: 72px;
  }
  .hookah1 {
    position: absolute;
    bottom: 40px;
  }
}

.controlled-img {
  min-height: 816px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.welcome-sec {
  background-color: #21040c;
  background-image: url(../assets/bookatable-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  h2 {
    font-size: 64px;
    color: #d1b982;
    font-family: "Yeseva One", serif;
  }
  h3 {
    font-size: 36px;
    color: #fff;
    font-family: "Yeseva One", serif;
  }
  .note {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #bbbbbb;
    font-size: 16px;
  }
  .form-control,
  .form-select {
    background-color: #000000;
    border-color: #505050;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    height: 60px;
  }
  .form-control::placeholder,
  .form-select::placeholder {
    color: #666666;
  }
  .book-btn {
    background-color: #7c9aa0;
    border-color: #7c9aa0;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    min-width: 300px;
    height: 60px;
  }
}

.happy-hours-sec {
  background-image: url(../assets/happyhour-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Yeseva One", serif;
  overflow: hidden;
  .border1 {
    width: 380px;
    border: 3px solid #000;
    height: 30px;
    border-bottom: 0;
  }
  .border2 {
    width: 380px;
    border: 3px solid #000;
    height: 30px;
    border-top: 0;
  }
  .hh-text1 {
    font-size: 64px;
    color: #000;
  }
  .hh-text2 {
    font-size: 20px;
    color: #000;
    max-width: 300px;
    display: inline-block;
  }
  .hh-text2::before {
    content: "";
    background-color: #000;
    width: 100px;
    height: 2px;
    position: absolute;
    left: -108px;
    top: 14px;
  }
  .hh-text2::after {
    content: "";
    background-color: #000;
    width: 100px;
    height: 2px;
    position: absolute;
    top: 14px;
    margin-left: 10px;
  }
  .hh-text3 {
    font-size: 48px;
    color: #fff;
  }
  .hh-text4 {
    font-size: 24px;
    color: #000;
  }
}

.reservation-sec {
  background-color: #000;
  background-image: url(../assets/bigparty-bg-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 64px;
    color: #d1b982;
    font-family: "Yeseva One", serif;
  }
  .rs-text1 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 32px;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #bbbbbb;
    font-size: 16px;
  }
  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 36px;
    position: relative;
  }
  h3::after {
    content: "";
    background-color: #7c9aa0;
    width: 38px;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .form-control,
  .form-select {
    background-color: #000000;
    border-color: #505050;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    height: 60px;
  }
  .form-control::placeholder,
  .form-select::placeholder {
    color: #666666;
  }
  .reserve-btn {
    background-color: #7c9aa0;
    border-color: #7c9aa0;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    min-width: 300px;
    height: 60px;
  }
}

.reservation-sec::after {
  content: "";
  background-color: #000;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}

.home-contact-sec {
  background-color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
  h2 {
    font-size: 64px;
    color: #d1b982;
    font-family: "Yeseva One", serif;
  }
  .map {
    background-color: #ddd;
    height: 322px;
    width: 100%;
  }
  .lable {
    color: #fff;
  }
  .value {
    color: #087b6f;
  }
}

footer {
  background-color: #000;
  color: #4b4b4b;
  overflow: hidden;
  .footer-menu {
    padding-bottom: 30px;
    a {
      font-size: 16px;
      color: #4b4b4b;
      font-family: "Bungee", sans-serif;
      text-decoration: none;
      margin: 0 10px;
    }
  }
}

.home-menu {
  background-color: #041917;
  background-image: url(../assets/Menu-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  h2 {
    font-size: 64px;
    color: #d1b982;
    font-family: "Yeseva One", serif;
  }
  .menu-item {
    text-align: center;
    padding-bottom: 20px;
    .item-img {
      width: 163px;
      height: 163px;
      border: 2px solid #6f2e40;
      padding: 4px;
      margin: 0 auto;
      .img {
        max-width: 100%;
      }
    }
    .item-name {
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #fff;
    }
    .item-price {
      font-size: 32px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #7c9aa0;
    }
  }
  .flavour-box {
    padding: 0 50px;
    .flavour-name {
      color: #d1b982;
      text-transform: uppercase;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
    }

    .flavour-des {
      color: #fff;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
  }
  .hookah-tastes {
    background-image: url(../assets/hookah-flv-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #087B6F;
    .hookah-text1 {
      color: #3ECDBD;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
    }
    .hookah-text2 {
      font-size: 36px;
      color: #d1b982;
      font-family: "Yeseva One", serif;
    }
    .explore-btn {
      background-color: #052521;
      border-color: #087B6F;
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      min-width: 250px;
      height: 56px;
  }
  }
}

.large-gatherings {
  background-color: #21040c;
  overflow: hidden;
  color: #fff;
  padding-bottom: 100px;
  h3 {
    font-size: 48px;
    color: #c54a4a;
    font-family: "Yeseva One", serif;
    padding-top: 100px;
  }
  p,
  .count-text {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  .count-number {
    font-size: 96px;
    color: #fff;
    font-family: "Yeseva One", serif;
  }
}

@media (max-width: 768px) {
  .carousel-sec {
    min-height: 416px;
  }
  .carousel-sec .titles {
    line-height: 40px;
  }
  .carousel-sec .font1 {
    font-size: 34px;
  }
  .carousel-sec .font2 {
    font-size: 45px;
  }
  .carousel-sec .font3 {
    font-size: 45px;
  }
  .welcome-sec {
    padding-top: 30px;
  }
  .welcome-sec h2 {
    font-size: 33px;
  }

  .welcome-sec h3 {
    font-size: 24px;
  }
  .welcome-sec .book-btn {
    font-size: 16px;
    min-width: 150px;
    height: 50px;
  }
  .home-menu h2 {
    font-size: 33px;
  }
  .happy-hours-sec .border1, .happy-hours-sec .border2 {
    width: 300px;
  }
  .happy-hours-sec .hh-text1 {
    font-size: 54px;
  }
  .happy-hours-sec .hh-text4 {
    font-size: 18px;
  }
  .home-menu .menu-item .item-name {
    font-size: 18px;
  }

  .home-menu .menu-item .item-price {
    font-size: 24px;
}
.large-gatherings h3 {
  font-size: 33px;
  padding-top: 50px;
}
.large-gatherings p, .large-gatherings .count-text {
  font-size: 16px;
}
.large-gatherings .count-number {
  font-size: 66px;
}

  .reservation-sec::after {
    content: "";
    background-color: #000;
    width: 100%;
    height: auto;
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 0;
  }
  .reservation-sec h2 {
    font-size: 40px;
  }
  .reservation-sec .rs-text1 {
    font-size: 20px;
  }
  .reservation-sec h3 {
    font-size: 24px;
  }
  .reservation-sec .signup-btn {
    font-size: 16px;
    min-width: 150px;
    height: 50px;
  }
  .home-contact-sec h2 {
    font-size: 40px;
  }
  .home-menu .hookah-tastes .hookah-text1 {
    font-size: 18px;
  }
  .home-menu .hookah-tastes .hookah-text2 {
    font-size: 30px;
  }
}
