.hokah-content-area {
    background-color: #21040c;
    overflow: hidden;
    .title {
        font-family: "Yeseva One", serif;
        font-size: 64px;
        color: #087b6f;
        line-height: 60px;
    }
    p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
    }
    .escape-flavor {
        background-image: url(../assets/hookah/hookah-img1.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        min-height: 700px;
        .title2 {
            font-family: "Yeseva One", serif;
            font-size: 48px;
            color: #c54a4a;
            line-height: 48px;
        }
    }
    .title3 {
        font-family: "Yeseva One", serif;
        font-size: 32px;
        color: #7C9AA0;
    }
    .flavour-box {
        background-image: url(../assets/hookah/hookah-frame-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        .flavour-name {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 20px;
            color: #fff;
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
        .price {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 30px;
            color: #BBBBBB;
        }
    }
    .count {
        color: #fff;
        .number {
            font-family: "Yeseva One", serif;
            font-size: 96px;
        }
        .count-label {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 20px;
        }
    }
    .perfect-place {
        background-color: #21040c;
        padding: 6px;
        border: 2px solid #6f2e40;
        .bg-img {
            background-image: url(../assets/menu/reserve.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: 300px;
        }
        .book-btn {
            background-color: #d1b982;
            border-color: #d1b982;
            font-size: 20px;
            color: #000000;
            font-weight: 500;
            min-width: 250px;
            height: 60px;
        }
        .title4 {
            font-family: "Yeseva One", serif;
            font-size: 64px;
            color: #ffffff;
            line-height: 60px;
        }
    }
    .menu-item {
        font-family: "Poppins", sans-serif;
        .item-name {
            font-size: 24px;
            color: #fff;
        }
        .item-price {
            font-weight: 600;
            font-size: 24px;
        }
        .item-description{
            font-size: 16px;
            color: #8A8A8A;
        }
    }
    .menu-sub-title {
        color: #D1B982;
        font-size: 42px;
        font-family: "Yeseva One", serif;
    }
}

@media (max-width: 768px) {
    .hokah-content-area .title {
        font-size: 40px;
        line-height: 40px;
    }
    .hokah-content-area .escape-flavor .title2 {
        font-size: 24px;
        line-height: 24px;
    }
    .hokah-content-area .title3 {
        font-size: 24px;
    }
    .hokah-content-area p {
        font-size: 12px;
    }
    .hokah-content-area .count {
        padding-bottom: 20px;
    }
    .hokah-content-area .count .number {
        font-size: 60px;
    }
    .hokah-content-area .perfect-place .title4 {
        font-size: 38px;
    }
    .hokah-content-area .perfect-place .book-btn{
        font-size: 16px;
        min-width: 150px;
        height: 50px;
    }
    .hokah-content-area .escape-flavor {
        min-height: 250px;
    }
}
