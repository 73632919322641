.happy-hour-content-area {
    background-color: #21040c;
    overflow: hidden;
    .title {
        font-family: "Yeseva One", serif;
        font-size: 64px;
        color: #087b6f;
        line-height: 60px;
    }
    .title2 {
        font-family: "Yeseva One", serif;
        font-size: 48px;
        color: #fff;
        line-height: 48px;
    }
    p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
    }
    .happy-hour-day {
        background-image: url(../assets/hookah/hookah-frame-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 300px;
        padding: 20px;
        height: 436px;
        margin: 0 auto;
        margin-bottom: 30px;
        .bg {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 294px;
            color: #fff;
            font-family: "Sacramento", cursive;
            font-size: 48px;
            padding: 10px;
            display: flex;
            align-items: end;
        }
        .day {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 20px;
            color: #fff;
            font-style: italic;
        }
        .time {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 24px;
            color: #d1b982;
            font-style: italic;
        }
    }



    .perfect-place {
        background-color: #21040c;
        padding: 6px;
        border: 2px solid #6f2e40;
        .bg-img {
            background-image: url(../assets/menu/reserve.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: 300px;
        }
        .book-btn {
            background-color: #d1b982;
            border-color: #d1b982;
            font-size: 20px;
            color: #000000;
            font-weight: 500;
            min-width: 250px;
            height: 60px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        .title4 {
            font-family: "Yeseva One", serif;
            font-size: 64px;
            color: #ffffff;
            line-height: 60px;
        }
    }
}

@media (max-width: 768px) {
    .happy-hour-content-area .title {
        font-size: 40px;
        line-height: 40px;
    }
    .happy-hour-content-area .title2 {
        font-size: 34px;
        line-height: 34px;
    }
    .happy-hour-content-area p {
        font-size: 12px;
    }
    .happy-hour-content-area .perfect-place .title4 {
        font-size: 38px;
    }
    .happy-hour-content-area .perfect-place .book-btn {
        font-size: 16px;
        min-width: 150px;
        height: 50px;
    }
}
